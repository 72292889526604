import { initData } from "@/api/common/data";
import { statustag, parseTime, codeConvertLabel } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      data: [],
      url: '',
      finished: false,
      total: 0,
      page: 1,
      size: 5,
      params: {},
      query: {},
      time: 170,
      sort: ['id,desc'],
      isAdd: false,
      delLoading: false,
      showEmpty: true,
      selections: [],
      selectionText: "",
      checkedAll: 0,
    }
  },
  created() {
    this.init();
  },
  methods: {
    // 第一次加载数据 注意vant-list 开启immediate-check="false" 避免重复加载
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      this.page = 1
      this.isLoading();
      return new Promise((resolve, reject) => {
        this.loading = true
        initData(this.url, this.getQueryParams()).then(res => {
          this.total = res.totalElements
          this.data = res.content
          if (res.content.length > 0) {
            this.showEmpty = false
          } else {
            this.showEmpty = true
          }
          setTimeout(() => {
            if (this.total <= this.data.length) {
              this.isFinished();
            } else {
              this.page++;
            }
            this.loading = false
          }, this.time)
          resolve(res)
        }).catch(err => {
          this.loading = false
          reject(err)
        })
      })
    },
    // 向上拉起事件
    onLoad() {
      if (this.data.length >= this.total) {
        this.isFinished();
      } else {
        this.loading = true
        initData(this.url, this.getQueryParams()).then(res => {
          let list = res.content
          if (list) {
            this.data = this.data.concat(list);
          }
          if (this.data.length >= this.total) {
            this.isFinished();
          } else {
            this.page++;
            // 每次加载完后 需手动处理关闭loading 否则onload不会加载下一次
            this.loading = false
          }
        }).catch(err => {
          this.loading = false
        })
      }
    },
    isLoading() {
      this.loading = true
      this.finished = false
    },
    // 关闭拉起事件
    isFinished() {
      this.finished = true
      this.loading = false
    },
    // 查询
    toQuery() {
      this.total = 0
      this.page = 1
      this.size = 5
      this.init()
    },
    beforeInit() {
      return true
    },
    // 审批状态 返回label值
    getSpztLabel(shzt) {
      let approvalStatus = parseInt(shzt);
      return this.dict.xj_shzt.filter(item => {
        return parseInt(item.value) === approvalStatus
      }).map(item => { return item.label }).toString()
    },
    // 根据审批码,类型 匹配
    statustag,
    parseTime,
    codeConvertLabel,
    // 参数转化为
    getQueryParams() {
      Object.keys(this.query).length !== 0 &&
        Object.keys(this.query).forEach((item) => {
          if (this.query[item] === null || this.query[item] === "")
            this.query[item] = undefined;
        });
      return {
        page: this.page - 1,
        size: this.size,
        sort: this.sort,
        ...this.query,
      };
    }
  }
}
