<template>
  <div id="FloorLayer">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.keyword" show-action placeholder="请输入学生姓名或学号查询" @search="onSearch">
        <template #action>
          <div @click="toStuSearch">查询
          </div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index)  in data" :key="index">
        <van-cell @click="toDetail(row)">
          <template #title>
            <span class="custom-title hidden-text">
              <svg-icon icon-class="floor-level" size="20" style="margin-right:5px" /><span style="margin-left:3px">{{$route.query.title}}</span><span style="color:rgba(35, 171, 114, 1)">{{row.layer}}</span>
            </span>
          </template>
          <template #label>
            <div class="room-div">
              <van-row>
                <van-col span="9">
                  <span>
                    <span class="room-div-mian-text">{{row.startRoom}}</span>
                    <span class="room-div-other-text">{{" / "+row.countRoom}}</span><br />
                    <span class="room-div-other-text">启用/总房间</span>
                  </span>
                </van-col>
                <van-col span="9">
                  <span>
                    <span class="room-div-mian-text">{{row.bedInto}}</span>
                    <span class="room-div-other-text">{{" / "+row.bedCount}}</span><br />
                    <span class="room-div-other-text">已入住/总床位</span>
                  </span>
                </van-col>
                <van-col span="6">
                  <span>
                    <span class="room-div-mian-text">{{((row.bedInto/row.bedCount)*100).toFixed(1)+'%'}}</span><br />
                    <span class="room-div-other-text">入住率</span>
                  </span>
                </van-col>
              </van-row>
            </div>
          </template>
          <template #right-icon>
            <van-icon class="room-count" name="arrow" size="15" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
export default {
  name: "FloorLayer",
  mixins: [initData],
  data() {
    return {
      value: "",
    };
  },
  created() {},
  methods: {
    beforeInit() {
      this.url = "/api/apartment/layer";
      this.query.apartmentId = this.$route.query.id;
      return true;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    toDetail(data) {
      this.$router.push({
        path: "/gy/g03",
        query: {
          layer: data.layer,
          apartmentId: this.$route.query.id,
          floorCode: this.$route.query.floorCode,
          campusName: this.$route.query.campusName,
          title: this.$route.query.title + data.layer,
        },
      });
    },
    toStuSearch() {
      if (this.query.keyword) {
        this.$router.push({
          path: "/gy/g04",
          query: {
            title: this.$route.query.title,
            type: 2,
            floorCode: this.$route.query.floorCode,
            stuName: this.query.keyword,
          },
        });
      } else {
        this.toQuery();
      }
    },
    onSearch() {},
  },
};
</script>

<style lang='scss' scoped>
@import "~@/assets/styles/apartment/floor.scss";
::v-deep .van-cell {
  padding: 10px 16px 6px 16px;
}
</style>
